
.AuditOpinion-check-layout {
	padding: 20px;
	overflow-y: auto;
	height: 100%;
	.auditArea {
		position: absolute;
		right: 10px;
		top: 65px;
		height: 30px;
		background-color: white;
	}
	.el-collapse {
		border-bottom: none;
		.el-collapse-item {
			margin-bottom: 15px;
		}
		/deep/ .el-collapse-item__header {
			padding-left: 15px;
			font-size: 16px;
			background-color: #eff6fe;
		}
		/deep/ .el-collapse-item__wrap {
			border-bottom: none;
		}
		/deep/ .el-collapse-item__content {
			padding: 15px;
		}
	}
	.view-form {
		.el-col {
			padding: 10px !important;
			border: 2px solid #eee;
			margin-top: -1px;
			margin-left: -2px;
			word-break: break-all;
		}
		.el-col-24 {
			display: flex;
			padding: 0 !important;
			border: none !important;
		}
		.el-col-4 {
			text-align: right;
			background-color: #f7f7f7;
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}
	}
	.ml0 {
		margin-left: 0 !important;
		border-left: none !important;
	}
}
.btn {
	padding: 0 50px !important;
}
.tip-box {
	line-height: 25px;
	background: #ecf5ff;
	border-radius: 5px;
	border: 1px solid #d9ecff;
	color: #419eff;
	padding: 5px 15px;
	.left-indent {
		text-indent: 3.5rem;
	}
}
